<template>
  <BContainer class="v-registration-auth mt-4">
    <BForm @submit.prevent="onSubmit()" novalidate>
      <BCard no-body>
        <OCardHeader slot="header">
          {{ $t(`${prefix}.headline`) }}
        </OCardHeader>
        <BCardBody>
          <CModelAccountType show-applicants v-model="accountType" />
          <template v-if="accountType">
            <OInputValue
              required
              icon-name="user"
              identity="registration-auth-input-username"
              v-model.trim="username"
              :disabled="isLogged"
              :label="$t(`${prefix}.username`)"
              :description="$t(`${prefix}.username-description`)"
              :error="getErrorMessage($v.username, $t('validation-errors.username'))"
            />
            <OInputPassword
              required
              with-confirm
              identity-input="registration-auth-input-password"
              identity-confirm="registration-auth-input-password-confirm"
              v-model="password"
              :disabled="isLogged"
              :description="$t(`${prefix}.password-description`)"
              :confirm-label="$t(`${prefix}.confirm-password`)"
              :confirm-description="$t(`${prefix}.confirm-password-description`)"
              :error="getErrorMessage($v.password, $t('validation-errors.password'))"
            />
            <OInputValue
              required
              type="email"
              identity="registration-auth-input-email"
              icon-name="envelope"
              v-model="email"
              :disabled="isLogged"
              :label="$t(`${prefix}.email`)"
              :description="$t(`${prefix}.email-description`)"
              :error="getErrorMessage($v.email, $t('validation-errors.email'))"
            />
            <BFormCheckbox
              required
              class="mt-4"
              data-identity="registration-auth-checkbox-accept"
              v-model="accepted"
              :disabled="isLogged"
              :value="true"
              :unchecked-value="false"
            >
              <span v-html="$t(`${prefix}.accept-checkbox`)"></span>
            </BFormCheckbox>
          </template>
        </BCardBody>
        <BCardFooter>
          <CardButtons
            :error="error"
            :error-translation-key="`${prefix}.error-messages`"
            :disable-save-continue="isInvalid"
          />
        </BCardFooter>
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, minLength, required, helpers } from 'vuelidate/lib/validators';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { formValidationMixin } from 'library/components/src/tools/mixins/form-validation.mixin';
import { routingMixin } from 'library/components/src/tools/mixins/routing.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { USERNAME } from 'library/src/utilities/regular-pattern';
import { vuelidateToDataModel } from 'library/src/utilities/vue/vuelidate-to-data-model';
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';
import { empty } from 'library/src/utilities/types';

import CModelAccountType from '../../c/model/account-type';
import { BContainer, BForm, BCard, BCardFooter, BFormCheckbox, BCardBody } from 'bootstrap-vue';
import OInputValue from '../../o/input/value';
import OInputPassword from '../../o/input/password';
import CardButtons from '../../o/card/buttons';
import OCardHeader from '../../o/card/header';

const prefix = 'vw.registration-auth';

export default {
  mixins: [pageMixin, validationMixin, formValidationMixin, currentUserMixin, routingMixin],
  components: {
    BContainer,
    BForm,
    BCard,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    OCardHeader,
    OInputValue,
    OInputPassword,
    CardButtons,
    CModelAccountType,
  },
  data() {
    return {
      error: null,
      password: null,
      accepted: false,
      username: null,
      email: null,
    };
  },
  // define by router
  props: ['nextRoute'],
  computed: {
    prefix: () => prefix,
    isInvalid() {
      if (this.isLogged) {
        return false;
      }
      return !this.accepted || empty(this.email) || empty(this.username);
    },
    ...computedMapping('registration', ['accountType']),
  },

  validations: {
    username: {
      username: helpers.regex('username', USERNAME),
      required,
    },
    password: {
      password: {
        required,
        minLength: minLength(5),
      },
      confirm: {
        required,
      },
    },
    email: {
      required,
      email,
    },
    accepted: {
      required,
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.updateAuthentification();
  },

  methods: {
    /**
     * submit formular to backend
     *
     * @returns {Promise<Route>}
     */
    async onSubmit() {
      const { nextRoute } = this;

      // already active user
      if (this.isLogged) {
        return this.$router.push(nextRoute);
      }

      // form submit logic
      if (await this.isSubmitValid()) {
        const model = vuelidateToDataModel(this.$v);

        // fake confirm Email
        model.confirmPassword = model.password.confirm;
        model.password = model.password.password;
        model.confirmEmail = model.email;

        try {
          // regist
          await this.$api.auth.registration(model);

          // push notification
          this.$store.dispatch('notify', {
            lifetime: 5, // 5 seconds
            message: this.$t(`${prefix}.notify.success`),
          });

          // login
          await this.$store.dispatch('auth/login', { ...model, passive: true });

          // continue registration
          this.$router.push(nextRoute);
        } catch (e) {
          this.$logger.error(e);
          this.error = e;
        }
      }
    },

    updateAuthentification() {
      if (this.isLogged) {
        this.username = this.currentUser.username;
        this.email = this.currentUser.email;
        this.accepted = true;
      }
    },
  },
};
</script>
