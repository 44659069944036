<template>
  <div class="c-model-account-type">
    <OInputAbstract :label="label">
      <b-card-group deck>
        <OInputSelectCard v-model="model" v-bind="employerProps" />
        <OInputSelectCard v-model="model" v-bind="recruiterProps" />
        <OInputSelectCard v-if="showApplicants" v-bind="applicantsProps" @change="onSelectApplicants" />
      </b-card-group>
    </OInputAbstract>
  </div>
</template>

<script>
import { EMPLOYER, RECRUITER } from 'library/src/models/account-type.enum';

import OInputAbstract from '../../o/input/abstract';
import OInputSelectCard from '../../o/input/select/card';

const prefix = 'c.model-account-type';

export default {
  name: 'c-model-account-type',
  components: {
    OInputAbstract,
    OInputSelectCard,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default() {
        return this.$t(`${prefix}.label`);
      },
    },
    showApplicants: Boolean,
    value: {},
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    recruiterProps() {
      return this.getItemPropsByName('recruiter', RECRUITER);
    },
    employerProps() {
      return this.getItemPropsByName('employer', EMPLOYER);
    },
    applicantsProps() {
      return this.getItemPropsByName('applicants', 'applicants');
    },
  },
  methods: {
    getItemPropsByName(name, selectedValue) {
      const props = { ...this.$t(`${prefix}.${name}`) };

      if (props.bullets) {
        const bullets = [];
        for (let index in props.bullets) {
          bullets.push(props.bullets[index]);
        }
        props.bullets = bullets;
      }

      props.selectedValue = selectedValue;

      return props;
    },
    onSelectApplicants() {
      window.open(this.$t('links.marketing-applicant-page-url'), '_blank');
    },
  },
};
</script>
