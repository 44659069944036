<template>
  <ODialog
    v-model="isDisplayed"
    hide-header
    :success-button-label="$t(`${prefix}.to-login`)"
    hide-close-button
    hide-cancel-button
  >
    <b-card-body>
      <OContent name="registration-complete-dialog" />
    </b-card-body>
  </ODialog>
</template>
<script>
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import ODialog from '../dialog';
import OContent from '../content';

const prefix = 'o.dialog-thanks';

export default {
  name: 'o-dialog-thanks',
  mixins: [currentUserMixin],
  components: {
    OContent,
    ODialog,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
  },
  computed: {
    prefix: () => prefix,
    isDisplayed: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('change', newValue);

        if (!newValue) {
          this.toLogout();
        }
      },
    },
  },
};
</script>
