<template>
  <BContainer class="v-registration-overview mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <OCardHeader slot="header">
          {{ $t(`${prefix}.headline`) }}
        </OCardHeader>
        <BCardBody>
          {{ $t(`${prefix}.description`) }}
        </BCardBody>

        <BCardHeader>
          {{ $t(`${prefix}.headline-auth`) }}
        </BCardHeader>
        <BCardBody>
          <OValue label="fields.account-type">
            {{ get('account.accountType', 'generic.account-type') }}
          </OValue>
          <OValue label="fields.username">
            {{ username }}
          </OValue>
          <OValue label="fields.email">
            {{ email }}
          </OValue>
        </BCardBody>

        <BCardHeader class="toolbar-header">
          <div class="headline">
            {{ $t(`${prefix}.headline-account`) }}
          </div>
          <OEdit :to="{ name: 'account' }">
            {{ $t(`${prefix}.edit-label`) }}
          </OEdit>
        </BCardHeader>
        <BOverlay :show="!account">
          <BCardBody>
            <OValue label="fields.title">
              {{ get('account.person.title', 'generic.title') }}
            </OValue>
            <OValue label="fields.salutation">
              {{ get('account.person.salutation', 'generic.salutations') }}
            </OValue>
            <OValue label="fields.firstname">
              {{ get('account.person.firstname') }}
            </OValue>
            <OValue label="fields.lastname">
              {{ get('account.person.lastname') }}
            </OValue>
            <hr />
            <OValue label="fields.phone">
              {{ get('account.person.phone') }}
            </OValue>
            <OValue label="fields.additional-phone">
              {{ get('account.person.additionalPhone') }}
            </OValue>
            <hr />
            <OValue label="fields.job-title">
              {{ get('account.person.jobTitle') }}
            </OValue>
            <OValue label="fields.companyname">
              {{ get('account.person.companyName') }}
            </OValue>
            <OValue label="fields.vat-id">
              {{ get('account.person.vatId') }}
            </OValue>
            <hr />
            <OValue label="fields.street">
              {{ get('account.address.street') }}
            </OValue>
            <OValue label="fields.zip">
              {{ get('account.address.zip') }}
            </OValue>
            <OValue label="fields.locality">
              {{ get('account.address.locality') }}
            </OValue>
            <OValue label="fields.country">
              {{ get('account.address.countryCode', 'countries') }}
            </OValue>
          </BCardBody>
        </BOverlay>

        <template v-if="isRecruiter">
          <BCardHeader class="toolbar-header">
            <div class="headline">
              {{ $t(`${prefix}.headline-bank-account`) }}
            </div>
            <OEdit :to="{ name: 'bank-account' }">
              {{ $t(`${prefix}.edit-label`) }}
            </OEdit>
          </BCardHeader>
          <BOverlay :show="!bankData">
            <BCardBody>
              <OValue label="vw.registration-bank-account.owner">
                {{ get('bankData.name') }}
              </OValue>
              <OValue label="vw.registration-bank-account.bank-name">
                {{ get('bankData.bankName') }}
              </OValue>
              <OValue label="vw.registration-bank-account.iban">
                {{ get('bankData.iban') }}
              </OValue>
              <OValue label="vw.registration-bank-account.bic">
                {{ get('bankData.bic') }}
              </OValue>
            </BCardBody>
          </BOverlay>
        </template>

        <BCardFooter>
          <CardButtons
            :backTo="backRoute"
            :disable-save-continue="isDisable"
            :disable-back="isDisable"
            :submit-label="$t(`${prefix}.submit`)"
          />
        </BCardFooter>
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import OValue from '../../o/value';
import OEdit from '../../o/edit';

import { BCard, BCardFooter, BCardBody, BCardHeader, BForm, BContainer } from 'bootstrap-vue';
import CardButtons from '../../o/card/buttons';
import OCardHeader from '../../o/card/header';
import select from 'lodash.get';

const prefix = 'vw.registration-overview';

export default {
  mixins: [pageMixin, currentUserMixin],
  components: {
    BCard,
    BCardFooter,
    BCardBody,
    BForm,
    BContainer,
    OCardHeader,
    BCardHeader,
    CardButtons,
    OValue,
    OEdit,
  },
  data() {
    return {
      account: null,
      accountFields: [],
      bankData: null,
    };
  },
  computed: {
    prefix: () => prefix,
    username() {
      return select(this.currentUser, 'username');
    },
    email() {
      return select(this.currentUser, 'email');
    },
    backRoute() {
      if (this.isRecruiter) {
        return { name: 'import-condition' };
      }
      return { name: 'account' };
    },
    accountType() {
      return select(this, 'account.accountType');
    },
    isRecruiter() {
      return this.accountType === 'recruiter';
    },
    isDisable() {
      return [this.account].includes(null);
    },
  },

  validations: {},

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    // load current account data
    this.$api.account.get().then(account => (this.account = account));

    this.$api.account.getBankData().then(bankData => (this.bankData = bankData));
  },

  methods: {
    get(selector, translationPrefix = null) {
      const value = select(this, selector);

      if (!value || [null, 'null'].includes(value)) {
        return this.$t('generic.null');
      }

      if (translationPrefix) {
        return this.$t(`${translationPrefix}.${value}`);
      }

      return value;
    },
    async onSubmit() {
      this.$router.push({ name: 'conditions' });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-header {
  display: flex;

  > .headline {
    flex-grow: 1;
  }
}
</style>
