<template>
  <BContainer class="c-registration-user mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <OCardHeader slot="header">
          {{ $t(`${prefix}.headline`) }}
        </OCardHeader>
        <BCardBody>
          <CModelAccountType v-model="accountType" />
          <template v-if="accountType">
            <CModelCompany v-model="person" :show-errors="showErrors" />
            <CModelPerson v-model="person" :show-errors="showErrors" />
            <CModelAddress
              allow-autofill
              v-model="address"
              :labels="addressLabels"
              :show-errors="showErrors"
              :allowed-countries="allowedCountries"
            />
            <CModelContact phone-required :show-errors="showErrors" v-model="person" />
          </template>
        </BCardBody>
        <CardButtons
          slot="footer"
          error-translation-key="validation-errors"
          :back-to="backTo"
          :error="error"
          :disable-save-continue="!accountType"
        />
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { formValidationMixin } from 'library/components/src/tools/mixins/form-validation.mixin';
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';
import { COUNTRY_FILTER } from 'library/src/models/country-filter.enum';

import CardButtons from '../../o/card/buttons';
import CModelAddress from '../../c/model/address';
import CModelContact from '../../c/model/contact';
import CModelPerson from '../../c/model/person';
import CModelCompany from '../../c/model/company';
import CModelAccountType from '../../c/model/account-type';
import OCardHeader from '../../o/card/header';
import { BCard, BCardFooter, BCardBody, BCardHeader, BForm, BContainer } from 'bootstrap-vue';

const prefix = 'vw.registration-account-type';

export default {
  mixins: [pageMixin, validationMixin, currentUserMixin, formValidationMixin, languageMixin],
  components: {
    BCard,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BForm,
    BContainer,
    OCardHeader,
    CModelAccountType,
    CModelPerson,
    CModelContact,
    CModelCompany,
    CModelAddress,
    CardButtons,
  },
  data() {
    return {
      prefix,
      person: {},
      address: {},
      showPopover: false,
      allowedCountries: COUNTRY_FILTER,
    };
  },
  computed: {
    ...computedMapping('registration', ['accountType']),
    backTo() {
      return { name: 'registration' };
    },
    addressLabels() {
      return this.$t(`${prefix}.address-labels`);
    },
  },
  validations: {
    accountType: {
      required,
    },
    person: {
      companyName: {
        required,
      },
      vatId: {
        required,
      },
      jobTitle: {
        required,
      },
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.$api.account
      .get()
      // bind model with response
      .then(account => {
        this.accountType = account.accountType;
        this.person = account.person;
        this.address = account.address;
      });
  },
  methods: {
    async onSubmit() {
      const { language } = this;
      const model = {
        accountType: this.accountType,
        person: {
          ...this.person,
          language,
        },
        address: {
          ...this.address,
        },
      };

      // form submit logic
      if (await this.isSubmitValid()) {
        try {
          this.$logger.debug('Submit model', model);
          const account = await this.$api.account.put(model);

          const name = account.accountType === 'employer' ? 'overview' : 'bank-account';

          this.$router.push({ name });
        } catch (e) {
          this.error = e;
          this.$logger.warn(e);
        }
      }
    },
  },
};
</script>
