<template>
  <BContainer class="v-registration-import-condition mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <OCardHeader slot="header">
          {{ $t(`${prefix}.headline`) }}
        </OCardHeader>
        <BCardBody>
          <OContent name="import-condition" />
          <BFormCheckbox class="mt-4" v-model="accepted" required :value="true" :unchecked-value="false">
            <span v-html="$t(`${prefix}.accept-checkbox`)"></span>
          </BFormCheckbox>
        </BCardBody>
        <BCardFooter>
          <CardButtons :back-to="{ name: 'bank-account' }" :error="error" :disable-save-continue="!accepted" />
        </BCardFooter>
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { formValidationMixin } from 'library/components/src/tools/mixins/form-validation.mixin';
import { routingMixin } from 'library/components/src/tools/mixins/routing.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';
import { DOCUMENTS } from 'library/src/models/documents.enum';

import { BContainer, BForm, BCard, BCardFooter, BCardBody, BFormCheckbox } from 'bootstrap-vue';
import CardButtons from '../../o/card/buttons';
import OContent from '../../o/content';
import OCardHeader from '../../o/card/header';

const prefix = 'vw.registration-import-condition';

export default {
  mixins: [pageMixin, validationMixin, formValidationMixin, routingMixin, currentUserMixin],
  components: {
    BContainer,
    BForm,
    BCard,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    OCardHeader,
    OContent,
    CardButtons,
  },
  data() {
    return {
      accepted: false,
    };
  },
  computed: {
    prefix: () => prefix,
  },
  validations: {
    accepted: {
      required,
      sameAs: sameAs(() => true),
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    // only allow inactive user
    if (!this.isInactiveLogged) {
      this.routeToRegistration();
    }
  },

  methods: {
    async onSubmit() {
      // form submit logic
      if (await this.isSubmitValid()) {
        try {
          await this.$api.auth.acceptDocument(DOCUMENTS.IMPORT_CONDITION, this.accepted);
          this.$router.push({ name: 'overview' });
        } catch (e) {
          this.error = e;
        }
      }
    },
  },
};
</script>
