<template>
  <BButton
    class="edit-button is-not-printable"
    variant="primary"
    size="sm"
    :title="title"
    :to="to"
    @click="$emit('click', $event)"
  >
    <SOIcon name="pen" size="sm" class="icon" />
  </BButton>
</template>
<script>
import { BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

const prefix = 'o.edit';

export default {
  name: 'o-edit',
  components: { SOIcon, BButton },
  props: {
    title: {
      type: String,
      default() {
        return this.$t(`${prefix}.title`);
      },
    },
    to: [String, Object],
  },
};
</script>
