<template>
  <BContainer class="v-registration-condition mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <OCardHeader slot="header">
          {{ $t(`${prefix}.headline-${accountType}`) }}
        </OCardHeader>
        <BCardBody v-if="accountType">
          <OContent :name="`condition-${accountType}`" />
          <BFormCheckbox
            class="mt-4"
            v-model="accepted"
            :value="true"
            :unchecked-value="false"
            @change="$v.accepted.$touch()"
          >
            <span v-html="$t(`${prefix}.accept-checkbox-${accountType}`)"></span>
          </BFormCheckbox>
        </BCardBody>
        <BCardFooter>
          <CardButtons
            :backTo="backRoute"
            :error="error"
            :disable-save-continue="$v.$invalid"
            :submit-label="$t(`${prefix}.submit`)"
          />
        </BCardFooter>
      </BCard>
    </BForm>
    <ODialogThanks v-model="isCompleted" />
  </BContainer>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { routingMixin } from 'library/components/src/tools/mixins/routing.mixin';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { BCard, BCardFooter, BCardBody, BForm, BContainer, BFormCheckbox } from 'bootstrap-vue';
import ODialogThanks from '../../o/dialog/thanks';
import OContent from '../../o/content';
import SOIcon from 'library/components/src/components/so/icon';
import CardButtons from '../../o/card/buttons';
import OCardHeader from '../../o/card/header';

const prefix = 'vw.registration-condition';

export default {
  mixins: [pageMixin, validationMixin, routingMixin, currentUserMixin],
  components: {
    BCard,
    BCardFooter,
    BCardBody,
    BForm,
    BContainer,
    BFormCheckbox,
    OCardHeader,
    OContent,
    ODialogThanks,
    SOIcon,
    CardButtons,
  },
  data() {
    return {
      error: null,
      accepted: false,
      isCompleted: false,
    };
  },
  computed: {
    prefix: () => prefix,
    ...mapGetters('registration', ['accountType']),
    backRoute() {
      return { name: 'overview' };
    },
  },

  validations: {
    accepted: {
      required,
      sameAs: sameAs(() => true),
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });
  },

  methods: {
    async onSubmit() {
      this.error = null;

      // form submit logic
      if (!this.$v.$invalid) {
        try {
          await this.$api.auth.acceptDocument('condition', this.accepted);
          // submit logic
          this.isCompleted = true;
        } catch (e) {
          this.error = e;
        }
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
