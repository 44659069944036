<template>
  <BCard :class="className" :bg-variant="variantName" no-body @click="onClick">
    <BCardBody class="content no-selectable">
      <h2>{{ label }}</h2>
      <p class="text-dimmed" v-html="description"></p>
      <OList v-if="bullets" :items="bullets" />
    </BCardBody>
    <BCardFooter class="control-footer text-center" :footer-bg-variant="variantName">
      <div :class="controlClassName">
        <SOIcon class="control-icon" name="check" />
      </div>
    </BCardFooter>
  </BCard>
</template>

<script>
import { BCard, BCardFooter, BCardBody } from 'bootstrap-vue';
import OInputAbstract from '../abstract';
import SOIcon from 'library/components/src/components/so/icon';
import OList from '../../list';

export default {
  name: 'o-input-select-card',
  mixins: [OInputAbstract],
  components: {
    OList,
    OInputAbstract,
    BCard,
    BCardFooter,
    BCardBody,
    SOIcon,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      selected: false,
    };
  },
  props: {
    asGroup: Boolean,
    required: Boolean,
    value: {
      type: [String, Number, Boolean],
    },
    selectedValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    bullets: Array,
  },
  computed: {
    className() {
      return ['o-input-select-card', 'clickable', this.asGroup ? 'as-radio' : 'as-checkbox'];
    },
    variantName() {
      return this.selected ? 'primary' : 'white';
    },
    controlClassName() {
      const { selected } = this;
      return ['control', selected ? 'is-selected' : 'is-none-selected', { 'bg-light': !selected }];
    },
  },
  watch: {
    value() {
      this.updateState();
    },
  },

  beforeMount() {
    this.updateState();
  },

  methods: {
    updateState() {
      this.selected = this.value === this.selectedValue;
    },
    onClick() {
      let { selectedValue, selected, asGroup } = this;

      if (!asGroup) {
        selectedValue = selected ? null : selectedValue;
      }

      this.$emit('change', selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../component';

.o-input-select-card {
  border-radius: $border-radius;
}

.control {
  display: inline-block;
  @include rect(30px);
  border: 1px solid $color-border;

  &.is-none-selected {
    > .control-icon {
      visibility: hidden;
    }
  }
}

.control-footer {
  border: none;
}

.as-radio {
  .control {
    border-radius: 50%;
  }
}

.as-checkbox {
  .control {
    border-radius: $border-radius;
  }
}
</style>
