<template>
  <BContainer class="v-registration-bank-account mt-4">
    <BForm @submit.prevent="onSubmit()">
      <BCard no-body>
        <OCardHeader slot="header" :tooltip="$t(`${prefix}.tooltip`)">
          {{ $t(`${prefix}.headline`) }}
        </OCardHeader>
        <BCardBody>
          <OInputValue
            required
            icon-name="user"
            identity="bank-account-input-owner"
            :label="$t(`${prefix}.owner`)"
            :description="$t(`${prefix}.owner-description`)"
            :error="getErrorMessage($v.name, $t(`${prefix}.validation-errors.owner`))"
            v-model.trim="name"
          />

          <OInputValue
            required
            icon-name="landmark"
            identity="bank-account-input-bank-name"
            :label="$t(`${prefix}.bank-name`)"
            :description="$t(`${prefix}.bank-name-description`)"
            :error="getErrorMessage($v.bankName, $t(`${prefix}.validation-errors.bank-name`))"
            v-model.trim="bankName"
          />

          <OInputValue
            required
            icon-name="credit-card"
            identity="bank-account-input-iban"
            :label="$t(`${prefix}.iban`)"
            :description="$t(`${prefix}.iban-description`)"
            :error="getErrorMessage($v.iban, $t(`${prefix}.validation-errors.iban`))"
            v-model.trim="iban"
          />

          <OInputValue
            required
            icon-name="landmark"
            identity="bank-account-input-bic"
            :label="$t(`${prefix}.bic`)"
            :description="$t(`${prefix}.bic-description`)"
            :error="getErrorMessage($v.bic, $t(`${prefix}.validation-errors.bic`))"
            v-model.trim="bic"
          />
        </BCardBody>
        <BCardFooter>
          <CardButtons :back-to="backTo" :error="error" />
        </BCardFooter>
      </BCard>
    </BForm>
  </BContainer>
</template>

<script>
import { BContainer, BForm, BCard, BCardFooter, BCardBody } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { formValidationMixin } from 'library/components/src/tools/mixins/form-validation.mixin';
import { routingMixin } from 'library/components/src/tools/mixins/routing.mixin';
import { pageMixin } from 'library/components/src/plugins/page/mixin/page.mixin';

import { BANK_IBAN, BANK_BIC } from 'library/src/utilities/regular-pattern';
import OInputValue from '../../o/input/value';
import CardButtons from '../../o/card/buttons';
import OCardHeader from '../../o/card/header';

const prefix = 'vw.registration-bank-account';

export default {
  mixins: [pageMixin, validationMixin, formValidationMixin, routingMixin, currentUserMixin],
  components: {
    BContainer,
    BForm,
    BCard,
    BCardFooter,
    BCardBody,
    OCardHeader,
    OInputValue,
    CardButtons,
  },
  data() {
    return {
      name: null,
      bankName: null,
      iban: null,
      bic: null,
    };
  },
  computed: {
    prefix: () => prefix,
    model() {
      const { name, bankName, iban, bic } = this;
      return { name, bankName, iban, bic };
    },
    backTo() {
      return { name: 'account' };
    },
  },
  validations: {
    name: {
      required,
    },
    bankName: {
      required,
    },
    iban: {
      iban: helpers.regex('iban', BANK_IBAN),
      required,
    },
    bic: {
      iban: helpers.regex('bic', BANK_BIC),
      required,
    },
  },

  // hooks
  beforeMount() {
    this.setPageParams({
      sectionGroup: this.$t(`vw.registration.page.group`),
      sectionTitle: this.$t(`${prefix}.page.title`),
    });

    this.$api.account
      .getBankData()
      // bind model with response
      .then(model => {
        Object.assign(this, model);
      });
  },
  methods: {
    async onSubmit() {
      // form submit logic
      if (await this.isSubmitValid()) {
        try {
          const { model } = this;
          await this.$api.account.putBankData(model);
          this.$router.push({ name: 'import-condition' });
        } catch (e) {
          this.error = e;
        }
      }
    },
  },
};
</script>
